
import { createSignal, For, Show } from "solid-js";
import { createStore, } from "solid-js/store";
import { useNavigate } from "@solidjs/router";

import { AvatarWithWfButtons, showWfCustomerMatchingNotSelectedItems, showWfCustomerMatchingOpendItems } from '../SmallComponents/SmallAvatarWithWfButtons'

import { CardWithMinimize } from '../AppMenuBar/CardList'
import { SelectChangeEvent } from "@suid/material/Select";
import { isSmall, isMedium, } from "../../../utils/sizeUtil"

import { ContractRates } from '../MediumComponents/MediumContractRatesNew'
import { Button, Typography,  Card, Stack, Grid, Alert, Avatar, } from "@suid/material";

import { GREY_COLOR, CARD_MARGIN, CARD_DISPLAY, CARD_COLOR_L1, CARD_COLOR_L2, CARD_COLOR_L3 } from '../UIConstants';

import { DateRangeInput } from '../SmallComponents/SmallFieldDate'

import { PageListMenu } from '../SmallComponents/SmallListMenu';
import { customerMatchingMenu } from "../../../utils/menuUtil"
 
import { CandidateAvatar } from '../SmallComponents/SmallCandidatesList'
import { CandidateProgress } from '../SmallComponents/SmallCandidateProgress' 
import { RatingComment } from '../SmallComponents/SmallShowRatingComment';
import { WFStatusFooter } from '../SmallComponents/SmallShowWFStatusFooter';
import { isDark } from '../AppMenuBar/AppBarToogleColorMode';
import { getDummyAvatar } from '../../../utils/getdummyavatar'
import { getText } from '../SmallComponents/Translate' 

const CustomerMatchingComponent = () => {

    const navigate = useNavigate();
    const [open, setOpen] = createSignal(false);
    const handleOpen = () => {
        setOpen(true);
        navigate('/customerjobroleview')
    }
    const handleOpenCustomerAssignment = () => {
        navigate('/customerassignment')
    }
    const handleClose = () => setOpen(false);

    const [anchorEl, setAnchorEl] = createSignal<Element | null>(null);

    const openPopover = () => Boolean(anchorEl());
    const handleOpenSocialMedia = (event: { currentTarget: Element }) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseSocialMedia = () => {
        setAnchorEl(null);
    };

    const [rateValue, setRateValue] = createSignal("120");
    const handleRateValueChange = (newRate: string) => {
        setRateValue(newRate);
    };


    const [rateModel, setRateModel] = createSignal("Simple");
    const handleRateModel = (event: MouseEvent,
        newRateModel: string) => {

        setRateModel(newRateModel);
    };


    const [state, setState] = createStore({
        workModel: 'Remote',
        remoteWork: '3',
        rateModel: 'Simple',
        expenseModel: 'AllInclusive',
        fromDate: '2023-05-01',
        toDate: '2023-05-15',
        calendarDays: 0,
        allocation: '8',
        allocationOnSite: '0',
        allocationRemote: '0',
        allocationUnits: 'Hours' || 'Days',
        allocationUnitsPr: 'Day' || 'Week' || 'Month',
        currency: 'EUR',
        ratePer: 'Hour' || 'Day' || 'Week' || 'Month',
        rateValue: 0,
        remoteRateValue: 0,
        onSiteRateValue: 0,
        expenseValue: 0,
        remoteExpenseValue: 0,
        onSiteExpenseValue: 0,
        totalHours: 0,
        totalOnSiteHours: 0,
        totalRemoteHours: 0,

        sumRates: '0.00',
        sumExpenses: '0.00',
        sumTotal: '0.00',
    });


    const handleChange = (key: keyof typeof state, newValue: any) => {
        setState(key, newValue);
    };

    const avatarSignContract = [
        { wfStatus: "87%", candidateName: "Ida Madson", jobName: "S/4 upgrade", src: "/assets/imgs/avatar2.jpg" },
        { wfStatus: "89%", candidateName: "Billy Turner", jobName: "Welcome music", role: "Senior Developer", src: getDummyAvatar() },
        { wfStatus: "82%", candidateName: "Edith Smith", src: getDummyAvatar() },
        { wfStatus: "89%", candidateName: "Ray Bitter", src: getDummyAvatar() },
        { wfStatus: "72%", candidateName: "Tina Turner", src: getDummyAvatar() },
    ]
    const avatarOpenMatches = [
        { candidateName: "Project Manager", jobName: "HR Upgrade", role: "Project Manager", src: "", jobStartDate: "Jun 2020", jobEndDate: "Dec 2023", onSiteText: "5 days onsite", wfStatus: "Candidates identified" },
    ]


    const [fromDate, setFromDate] = createSignal('2022-01-01');
    const [toDate, setToDate] = createSignal('2022-01-31');
    const handleFromDateChange = (value: string) => {
        setFromDate(value);
        // setSumCalendarDays(calculateDaysBetween(new Date(fromDate()), new Date(toDate())).toString())
    };
    const handleToDateChange = (value: string) => {
        setToDate(value);
        // setSumCalendarDays(calculateDaysBetween(new Date(fromDate()), new Date(toDate())).toString())
    };

    const [allocation, setAllocation] = createSignal('12');

    const handleAllocationUnitsChange = (value: string) => {
        setAllocationUnits(value);
    };

    const [currency, setCurrency] = createSignal("EUR");
    const handleCurrencyChange = (event: SelectChangeEvent) => {
        setCurrency(event.target.value);
    };

    const [ratePer, setRatePer] = createSignal("Hour");
    const handleRatePer = (newRatePer: string) => {
        setRatePer(newRatePer);
    };


    const [allocationUnits, setAllocationUnits] = createSignal("Hours");
    const [allocationUnitsPr, setAllocationUnitsPr] = createSignal("Days");

    const txtStatus = getText("default", "status")
    const txtWaitingInterview = getText("interviewcomponent", "waitinginterview")
    const txtMatchSimulation = getText("matchpage", "matchsimulation")
    const txtBestMatch = getText("matchpage", "bestmatch")
    const txtAlertHighDemand = getText("matchpage", "alerthighdemand")
    const txtWfStatusIdentified = getText("workflowcomponent", "identified")
    const txtDays = getText("default", "days")
    const txtOnsite = getText("default", "onsite")
    const txtTbd = getText("default", "tbd")
    const txtNotSelected = getText("matchpage", "notselected")
    const txtFailedInterview = getText("matchpage", "failedinterview")
    const txtChosedOther = getText("matchpage", "chosedother")
    const txtNotAvaiable = getText("matchpage", "notavaiable")
    const txtMatchingHistory = getText("matchpage", "matchinghistory")
    const txtIdentified = getText("matchpage", "identified")
    const txtMatchIdentified = getText("matchpage", "matchidentified", [{ name: "name", value: "Teddy Smith" }, { name: "MatchPct", value: "94" }])
    const txtContract = getText("matchpage", "contract")
    const txtMatchStatus = getText("matchpage", "matchstatus")
    const txtListNewOrder = getText("matchpage", "listneworder")
    const txtPriceUpdated = getText("matchpage", "priceupdated", [{ name: "name", value: "Henry Smith" }])

    const selectDark = isDark()

    const [openFilter, setOpenFilter] = createSignal(false);
    const handleOpenFilter = () => {
        alert('handleOpenFilter')
        setOpenFilter(true);
    }

    return (

        <>



            {/* View section for matching */}
            <Card sx={{ margin: 1 }}>

                <Grid container {...isSmall() ? { direction: 'column' } : { direction: 'row' }} >

                    <Grid item xs={12} >
                        {/* Addition navigation options for the customer */}
                        <Stack
                            direction="row"
                            alignItems="left"
                            spacing={2}
                            sx={{ margin: 1, display: 'flex' }}
                        >
                            <PageListMenu options={customerMatchingMenu} handleEvent={handleOpenFilter} />

                        </Stack>

                        {/* Status of the matching process */}
                        <Alert sx={{ px: 1, py: 1 }} severity="info">
                            {txtMatchStatus()}
                        </Alert>
                    </Grid>


                    {/* Match simulation */}
                    <Grid item xs={12} >

                        <CardWithMinimize iconName="matching" header={txtMatchSimulation() as string} type="stack" defaultOpen={true}>

                            <Stack direction="column" margin={1} spacing={1} flexDirection="column" flexGrow={1}>

                                {/* Jobinfo for job to find a match for*/}
                                <Card sx={{ backgroundColor: (selectDark ? CARD_COLOR_L2 : GREY_COLOR) }} >
                                    <Stack direction="row"  >

                                        <For each={avatarOpenMatches}>
                                            {(item) => <AvatarWithWfButtons showWfItems={showWfCustomerMatchingOpendItems} avatarSize="medium" direction="row" avatarName={item.candidateName} jobName={item.jobName} role={item.role} src={item.src} onSiteText={item.onSiteText} jobStartDate={item.jobStartDate} jobEndDate={item.jobEndDate} wfStatus={item.wfStatus} />}
                                        </For>

                                    </Stack>
                                </Card>


                                {/* Section for simulation of input to the AI*/}
                                <Card sx={{ backgroundColor: (selectDark ? CARD_COLOR_L2 : GREY_COLOR) }} >
                                    <Stack margin={1} spacing={2} >


                                        {/* Contract Rates - both simple and advanced*/}
                                        <ContractRates
                                            calendarDays={state.calendarDays}
                                            showAllocation={true}    
                                            numberOfHours={String(state.totalHours)}
                                            setHours={(newValue) => handleChange('totalHours', newValue)}
                                            numberOfOnSiteHours={state.allocationOnSite}
                                            numberOfRemoteHours={state.allocationRemote}
                                            setOnSiteAllocation={(newValue) => handleChange('allocationOnSite', newValue)}
                                            setRemoteAllocation={(newValue) => handleChange('allocationRemote', newValue)}

                                            allocation={state.allocation}
                                            setAllocation={(newValue) => handleChange('allocation', newValue)}
                                            allocationUnits={state.allocationUnits}
                                            setAllocationUnits={(newValue) => handleChange('allocationUnits', newValue)}
                                            allocationUnitsPr={state.allocationUnitsPr}
                                            setAllocationUnitsPr={(newValue) => handleChange('allocationUnitsPr', newValue)}

                                            rateModel={state.rateModel}
                                            setRateModel={(newValue) => handleChange('rateModel', newValue)}
                                            expenseModel={state.expenseModel}
                                            setExpenseModel={(newValue) => handleChange('expenseModel', newValue)}

                                            currency={state.currency}
                                            setCurrency={(newValue) => handleChange('currency', newValue)}

                                            ratePer={state.ratePer}
                                            setRatePer={(newValue) => handleChange('ratePer', newValue)}

                                            rateValue={state.rateValue.toString()}
                                            remoteRateValue={state.remoteRateValue.toString()}
                                            onSiteRateValue={state.onSiteRateValue.toString()}
                                            setRateValue={(newValue) => handleChange('rateValue', newValue)}
                                            setRemoteRateValue={(newValue) => handleChange('remoteRateValue', newValue)}
                                            setOnSiteRateValue={(newValue) => handleChange('onSiteRateValue', newValue)}

                                            expenseValue={state.expenseValue.toString()}
                                            remoteExpenseValue={state.remoteExpenseValue.toString()}
                                            onSiteExpenseValue={state.onSiteExpenseValue.toString()}
                                            setExpenseValue={(newValue) => handleChange('expenseValue', newValue)}
                                            setRemoteExpenseValue={(newValue) => handleChange('remoteExpenseValue', newValue)}
                                            setOnSiteExpenseValue={(newValue) => handleChange('onSiteExpenseValue', newValue)}

                                            totalHours={state.totalHours}
                                            totalRemoteHours={state.totalRemoteHours}
                                            totalOnSiteHours={state.totalOnSiteHours}

                                            sumRates={state.sumRates}
                                            sumExpenses={state.sumExpenses}
                                            sumTotal={state.sumTotal}
                                            setSumRates={(newValue) => handleChange('sumRates', newValue)}
                                            setSumExpenses={(newValue) => handleChange('sumExpenses', newValue)}
                                            setSumTotal={(newValue) => handleChange('sumTotal', newValue)}
                                        />


                                    </Stack>
                                </Card>

                                <Alert sx={{ px: 1, py: 1 }} severity="warning" >
                                    {txtAlertHighDemand()}
                                </Alert>

                            </Stack>

                        </CardWithMinimize>

                        {/* -- End of simulation part -- */}

                    </Grid>


                    {/* Best match */}
                    <Grid container direction='row'  >

                        <CardWithMinimize iconName="heart" header={txtBestMatch() as string} type={isSmall() || isMedium() ? "list" : "stack"} defaultOpen={true}>

                            

                                {/* Match #1 */}
                                <Grid item xs={12} sm={12} md={12} lg={6}>
                                    <Stack sx={{ alignItems: 'center' }}  >
                                        <Button onclick={handleOpenCustomerAssignment}>
                                            <Avatar sx={{ width: 56, height: 56, bgcolor: '#8385C2' }}>1</Avatar>
                                        </Button>
                                        <CandidateProgress />

                                        <Typography variant="body1" sx={{ px: 1, fontSize: 14 }} color="text.secondary"  > {txtStatus()} </Typography>
                                        <Typography variant="body1" sx={{ px: 1, fontSize: 14 }} color="success.dark"> {txtWaitingInterview()}</Typography>

                                    </Stack>
                                    <CandidateAvatar
                                        layout="grid"
                                        candidateName="Benny Hansen"
                                        jobName="Benny is a very experienced project manager working out of Münich. He has a master in business science from Münich university. He served cusomers as e.g. Coca-Cola, Audi with agile project management"
                                        jobRole="Project Manager"
                                        logoUrl={getDummyAvatar()}
                                        rateValue={120}
                                        rateCurrency='USD'
                                        rating={3.8}
                                        jobStartDate="Jun 2020" jobEndDate="Dec 2023"
                                        matchPct={87}
                                        wfStatus="Waiting for Interview"
                                        onSiteText="5 days onsite"
                                    />


                                </Grid>
                                {/* Match #2 */}
                                <Grid item xs={12} sm={12} md={12} lg={6}>
                                    <Stack sx={{ alignItems: 'center' }} >
                                        <Button onclick={handleOpenCustomerAssignment}  >
                                            <Avatar sx={{ width: 56, height: 56, bgcolor: '#8385C2' }} >2</Avatar>
                                        </Button>
                                        <CandidateProgress />

                                        <Typography variant="body1" sx={{ px: 1, fontSize: 14 }} color="text.secondary"  > Status </Typography>
                                        <Typography variant="body1" sx={{ px: 1, fontSize: 14 }} color="success.dark"> Contract Sendt</Typography>

                                    </Stack>
                                    <CandidateAvatar
                                        layout="grid"
                                        candidateName="Teddy Weelly"
                                        jobName="Teddy is a very experienced project manager working out of Münich. He has a master in business science from Münich university. He served cusomers as e.g. Coca-Cola, Audi with agile project management"

                                        jobRole="Project Manager"
                                        logoUrl={getDummyAvatar()}
                                        rateValue={120}
                                        rateCurrency='USD'
                                        rating={3}
                                        jobStartDate="Jun 2020" jobEndDate={txtTbd() as string}
                                        matchPct={78}
                                        wfStatus={txtWfStatusIdentified() as string}
                                        onSiteText={txtTbd() as string}
                                    />
                                </Grid>
                         

                        </CardWithMinimize>




                    </Grid>
                </Grid>


                <Grid container direction='row' >

                    {/* Not selected candidates */}
                    <Grid item xs={12} >
                        <CardWithMinimize header={txtNotSelected() as string} type="stack" defaultOpen={true}>
                            <For each={avatarSignContract}>
                                {(item) => <AvatarWithWfButtons  showWfItems={showWfCustomerMatchingNotSelectedItems} wfStatus={item.wfStatus} avatarName={item.candidateName} jobName={item.jobName} role={item.role} src={item.src} />}
                            </For>
                        </CardWithMinimize>
                    </Grid>


                    {/* Matching history */}
                    <Grid item xs={12} >
                        <CardWithMinimize header={txtMatchingHistory() as string} type="stack" defaultOpen={false}>
                            <Stack direction="column" alignItems="left" width="100%" sx={{ bgcolor: 'background.default' }} >


                                <Card sx={{ margin: 1, bgcolor: 'background.default'[50], minWidth: '500' }}>
                                    <RatingComment comment={txtMatchIdentified() as string} />

                                </Card>
                                <WFStatusFooter type="customer" actionDate="18.3.22 7:48" userName="Core system" >{txtIdentified() as string} </WFStatusFooter>


                                <Card sx={{ margin: 1, bgcolor: 'background.default'[50], minWidth: '500' }}>
                                    <RatingComment comment={txtMatchIdentified() as string} />

                                </Card>
                                <WFStatusFooter  type="customer" actionDate="18.3.22 7:48" userName="Sired Øystein" >{txtContract() as string}</WFStatusFooter>


                                <Card sx={{ margin: 1, bgcolor: 'background.default'[50], minWidth: '500' }}>
                                    <RatingComment comment={txtListNewOrder() as string} />

                                </Card>
                                <WFStatusFooter  type="customer" actionDate="18.3.22 7:48" userName="Sired Øystein" >{txtContract() as string}</WFStatusFooter>


                                <Card sx={{ margin: 1, bgcolor: 'background.default'[50], minWidth: '500' }}>
                                    <RatingComment comment={txtListNewOrder() as string} />

                                </Card>
                                <WFStatusFooter  type="customer" actionDate="18.3.22 7:48" userName="Sired Øystein" >{txtContract() as string}</WFStatusFooter>


                                <Card sx={{ margin: 1, bgcolor: 'background.default'[200], minWidth: '500' }}>
                                    <RatingComment comment={txtPriceUpdated() as string} />

                                </Card>
                                <WFStatusFooter type="customer"  actionDate="18.3.22 7:48" userName="Sired Øystein" >{txtContract() as string} </WFStatusFooter>

                            </Stack>
                        </CardWithMinimize>
                    </Grid>

                </Grid>

            </Card>
<br />
<br />
        </>
    );
}
export { CustomerMatchingComponent }