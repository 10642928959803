
import { PageTemplate } from './PageTemplate' 
import { WFMatchingButtons } from '../components/Workflow/WfActionsComponent';
import { CustomerMatchingsComponent } from '../components/Customer/CustomerMatchingsComponent'
import { CustomerMatchingComponent } from '../components/Customer/CustomerMatchingComponent'

const ShowCustomerMatchingPage = () =>{
    return (
        <PageTemplate
            type="customer" headerPage="quicklauncher" headerElement="matching"
            leftPaneComponent={CustomerMatchingsComponent()} mainComponent={CustomerMatchingComponent()} buttonComponent={WFMatchingButtons()} >
        </PageTemplate>
    );
}
export {ShowCustomerMatchingPage}